.SectionComponent {
  // Ensure container is above bgImage
  .container {
    position: relative;
  }

  // Add light border if two white
  // sections next to each other.
  .bg-white + &.bg-white {
    border-top: 1px solid #1e75d9;
  }
  .bg-dark + &.bg-dark {
    border-top: 1px solid #9EDDF8;
  }
}
