.NavbarCustom {
  &__no-classname {
    margin-right: 10px;
  }

  &__no-classname {
    margin-right: 10px;
  }

  &__no-classname {
    margin-right: 10px;
  }

  &__no-classname {
    margin-right: 10px;
  }
}
