.cursor-pointer{
    cursor: pointer;
}

.py-5{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.navbar-dark.bg-dark {
    background-color: #384357 !important;
}

.bg-dark .animate:hover {
    box-shadow: 0px 0px 4px 5px #fcabab;
    cursor: pointer;
}

.bg-white .animate:hover {
    box-shadow: 0px 0px 4px 5px #9A2726;
    cursor: pointer;
}

.animate{
    box-shadow: 1px 2px 9px rgba(200,199,200,0.5), 0 -3px 6px rgba(200,199,200, 0.5);
}
.card{
    border-radius: 0 !important;
}

.navbar-dark .navbar-nav .nav-link{
    color: #9EDDF8 !important;
}

.navbar-light .navbar-nav .nav-link{
    color: #1E75D9 !important;
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
    color: #ffffff !important;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus{
    color: #000000 !important;
}

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active{
    color: #000000 !important;
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active{
    color: #ffffff !important;
}

.text-light h1, .custom-h1.text-light {
    color: #9EDDF8 !important;
}

.text-dark h1, .custom-h1.text-dark {
    color: #1E75D9 !important;
}

.header-center{
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.increase-font{
    font-size: 18px;
}

.form-control{
    border-radius: none;
}